
import './bootstrap';
import { forEach } from 'lodash';
import 'remixicon/fonts/remixicon.css';
const Swal = require('sweetalert2')
global.Swal = Swal;

import Toastify from 'toastify-js';
import "toastify-js/src/toastify.css"

import Inputmask from "inputmask";
global.Inputmask = Inputmask;

window.$ = window.jQuery = require('jquery');

var selector = document.querySelectorAll(".input_number");
Inputmask('decimal',
    {
        prefix:"",
        inputmode:"decimal",
        groupSeparator:".",
        autoGroup:!0,
        digits:2,
        digitsOptional:!0,
        radixPoint:","
    }
).mask(selector);

var selector = document.querySelectorAll(".input_number_mq");
Inputmask({"mask": "99999999","placeholder":""}).mask(selector);

$(document).keypress(
    function(event){
      if (event.which == '13') {
        event.preventDefault();
      }
  });

$('#reset').on('click', function () {
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });
    $.ajax({

        type: 'POST',

        url: '/reset-richiesta',

        success: function (response) {

            console.log(response)
            window.location.href = "home";

        }

    });
})

$('#saveData').on('click', function () {

    const data = $('form').serializeArray();

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });
    $.ajax({

        type: 'POST',

        data: data,

        url: '/storeCustomer',

        success: function (response) {

            readHTML()

            /*   Swal.fire({
                  icon: 'success',
                  title: 'Dati salvati correttamente',
                  confirmButtonText: 'OK',
                  confirmButtonColor: '#3085d6',
                }).then((result) => {
                  if (result.isConfirmed) {
                      window.location.href = "/";
                  }
                }) */

        }

    });
})

$('#deleteConf').on('click', function () {

    const getSlug = document.querySelector("#deleteConf");
    const slug = getSlug.dataset.slug;

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });
    $.ajax({

        type: 'DELETE',

        url: '/delete/' + slug,

        success: function (response) {

            window.location.href = response.url;
        }

    });
})

function readHTML() {
    $.ajax({
        url: "generate-pdf",
        success: function (result) {

            $("#dataResult").html(result)

        }
    });

}

$('#test').on('click', function (e) {
    e.preventDefault();

    let result = sitemaSolare(300, 'Lazio', 2859, 'no', 'no', 'no');
    console.log(result)
})

$('#sendEmail').on('click', function () {

    $('.lds-ring').addClass('loading');
    $(this).prop("disabled", true);

    const slug = this.dataset.slug;

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });
    $.ajax({

        type: 'POST',

        url: '/send-email/' + slug,

        beforeSend: function () {

            $('.lds-ring').addClass('loading');
            $(this).prop("disabled", true);

        },

        success: function (response) {

            Swal.fire({
                icon: 'success',
                title: 'Email inviata',
                showConfirmButton: false,
                timer: 1500

            })

            $('.lds-ring').removeClass('loading');
            $('#sendEmail').prop("disabled", false);

        }

    });
})

$('#btn-accept').on('click', function () {

    $('#btn-accept .lds-ring').addClass('loading');
    $(this).prop("disabled", true);

    const slug = this.dataset.slug;

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });
    $.ajax({

        type: 'POST',

        url: '/accept-configure/' + slug,

        beforeSend: function () {

            $('#btn-accept .lds-ring').addClass('loading');
            $(this).prop("disabled", true);

        },

        success: function (response) {

            Swal.fire({
                icon: 'success',
                title: 'Configurazione accettata',
                showConfirmButton: false,
                timer: 1500

            })
            $("#acceptDiv").load(window.location.href + " #acceptDiv");
            $('#btn-accept .lds-ring').removeClass('loading');
            $('#sendEmail').prop("disabled", false);

        }

    });
})

window.addEventListener('closeModal', event => {
    let modal = event.detail.idModal;
    console.log(modal)
    $(modal).modal('hide');

})
window.addEventListener('refreshDiv', event => {
    let id = event.detail.id;
    $( id ).load(window.location.href + " "+id);
})
window.addEventListener('Toastify', event => {
    Toastify({
        text: event.detail.text,
        duration: 2000,
        close: false,
        gravity: "top", // `top` or `bottom`
        position: "right", // `left`, `center` or `right`
        stopOnFocus: true, // Prevents dismissing of toast on hover
        style: {
            background: "#228B22",
        },
    }).showToast();
})

$(function () {
    $('[data-toggle="tooltip"]').tooltip()
  })

//DISABLE BTN

if (document.getElementById('formQuery'))
{
    var form = document.getElementById("formQuery");

    form.addEventListener("submit", function(event) {
        this.disabled = true;
        document.querySelector(".spinner-border").classList.remove('d-none');
        document.querySelector(".spinner-border").classList.add('d-inline-block');
    });
}

  //NOTIFICHE

var notifications = [];

const NOTIFICATION_TYPES = {
    chanceStatus: 'App\\Notifications\\ChanceStatusNotify',
    newLead: 'App\\Notifications\\NewLead'
};

$(document).ready(function() {
    if(Laravel.userId) {
    $.ajax({
        url : "/notifications",
        method : "GET",
        cache : false
    })
    .done(function(data){

        addNotifications(data, "#notifications");
    })
    .fail(function(data){
        console.log("Error in fetching data");
    })
}

});

function addNotifications(newNotifications, target) {
    notifications = _.concat(notifications, newNotifications);
    // show only last 5 notifications
    notifications.slice(0, 10);
    showNotifications(notifications, target);
}

function showNotifications(notifications, target) {

    showNotificationsCount(notifications.length)

    if(notifications.length) {
        var htmlElements = notifications.map(function (notification) {
            return makeNotification(notification);
        });
        $(target + 'Menu').html(htmlElements.join(''));
        $(target).addClass('has-notifications')
    } else {
        $(target + 'Menu').html('<li class="dropdown-header">Nessuna notifica</li>');
        $(target).removeClass('has-notifications');
    }
}

// Make a single notification string
function makeNotification(notification) {
    var to = routeNotification(notification);
    var notificationText = makeNotificationText(notification);
    return '<li><a href="' + to + '">' + notificationText + '</a></li>';
}

// get the notification route based on it's type
function routeNotification(notification) {

    var to = '?read=' + notification.id;
    if(notification.type === NOTIFICATION_TYPES.chanceStatus) {
        const slug = notification.data.slug;
        to = 'dashboard/opportunita/'+slug + to;
    } else if(notification.type === NOTIFICATION_TYPES.newLead) {

        /* const slug = notification.data.slug; */
        to = 'dashboard/leads/'+to+'&status=waiting';
    }
    return '/' + to;
}

// get the notification text based on it's type
function makeNotificationText(notification) {
    var text = '';

    if(notification.type === NOTIFICATION_TYPES.chanceStatus) {

        const chance = notification.data.chance;
        const slug = notification.data.slug;
        const status = notification.data.status;
        const user = notification.data.user;

        text += 'L\'opportunità n <strong>'+ slug +'</strong> è stata <strong>'+ status +'</strong> dall\'agente '+ user;
    }
    else if(notification.type === NOTIFICATION_TYPES.newLead) {
        var count  = '';
        if (notification.count === undefined) {
            count = notification.data.count;
          }else{
            count = notification.count;
          }

        text += `Hai <strong>${count}</strong> nuovi lead da gestire`;
    }

    return text;
}

function showNotificationsCount(count){
   const pattern = /^\(\d+\)/;

   if(count === 0 || pattern.test(document.title)){
        document.title = document.title.replace(pattern, count === 0 ? "" : "(" + count + ")");
   } else{
        document.title = "(" + count + ") " + document.title;
   }

   if(count != 0)
   {

    const para = document.createElement("span");
    para.className = 'badgeNotify'
    para.innerHTML = count;

    document.getElementById("wrapperNotify").appendChild(para);
   }

}

window.Echo.private(`App.Models.User.${Laravel.userId}`)
.notification((notification) => {
    addNotifications([notification], '#notifications');
});

var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
  return new bootstrap.Tooltip(tooltipTriggerEl)
})

//Open searchAdvance

const targetDiv = document.getElementById("wrapper_SA");
    const btn = document.getElementById("btnOpenSA");
if (btn) {
    btn.onclick = function () {
      if (targetDiv.style.display !== "none") {
        targetDiv.style.display = "none";
        btn.innerHTML  = 'Filtri avanzati'
      } else {
        targetDiv.style.display = "block";
        btn.innerHTML  = 'Chiudi'
      }
    };
}