window._ = require('lodash');

try {
  global.bootstrap = require('bootstrap');
} catch (e) {}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

window.Pusher = Pusher;

window.Echo = new Echo({
     broadcaster: 'pusher',
     key: process.env.MIX_PUSHER_APP_KEY,
     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
     forceTLS: true,
     encrypted: true
     });

     window.Echo.private('App.Models.User.'+window.Laravel.userId)
    .notification((notification) => {
        /* console.log(notification); */
    });

/*      window.Echo.channel('chat')
  .listen('MessageSent', (e) => {
     console.log(e)
     this.messages.push({
      message: e.message.message,
      user: e.user
    });
  }); */

/*     window.Echo.channel('statusChance')
     .listen('SendNotyStatusChance', (e) => {
        alert('ok');
         console.log(e);
 });
 */
